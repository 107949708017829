.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}
.ayur-card {
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.5s;
}
.ayur-card-front {
    width: 280px;
    height: 150px;
    border: 1px solid #ccc;
}

.ayur-card-back {
    width: 280px;
    height: 150px;
    color: #00000;
    background-color: #d4ebf2;
    border: 1px solid #ccc;
}
.ayur-card img {
    max-width: 100%;
    max-height: 100%;
}

.ayur-card-popout {
    background-color: #011332;
    padding: 60px;
    margin-top: 30px;
    margin-left: 60px;
    border: 1px solid #333;
    transition: 0.15s;
}
.ayur-card-popout:hover {
    transform: scale(1.09);
}